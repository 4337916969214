<template>
  <div>
    <div class="air__utils__heading">
      <h5>Control Operacional</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--    Enlaces-->
    <div class="row">

      <div class="col-lg-4 col-md-12 col-sm-12">
        <router-link :to="{ name: 'personalControl'}">
          <div class="card">
            <div class="card-title">
              <h1 class="text-block mt-5 mb-4 text-center">
                <b-iconstack font-scale="1.5" variant="primary">
                  <b-icon stacked icon="file-person" />
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Control de Personal</h6>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <router-link :to="{ name: 'operationEquipments', params: {type: 'Equipos Móviles'}}">
          <div class="card">
            <div class="card-title">
              <h1 class="text-block mt-5 mb-4 text-center">
                <b-iconstack font-scale="1.5" variant="primary">
                  <b-icon stacked icon="truck" />
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Equipos Móviles</h6>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <router-link :to="{ name: 'operationEquipments', params: {type: 'Equipos de Seguridad'}}">
          <div class="card">
            <div class="card-title">
              <h1 class="text-block mt-5 mb-4 text-center">
                <b-iconstack font-scale="1.5" variant="primary">
                  <b-icon stacked icon="shield-lock-fill" />
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Equipos de Seguridad</h6>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <router-link :to="{ name: 'operationEquipments', params: {type: 'Trituradoras'}}">
          <div class="card">
            <div class="card-title">
              <h1 class="text-block mt-5 mb-4 text-center">
                <b-iconstack font-scale="1.5" variant="primary">
                  <b-icon stacked icon="trash-fill" />
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Trituradoras</h6>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <router-link :to="{ name: 'operationEquipments', params: {type: 'Instalaciones'}}">
          <div class="card">
            <div class="card-title">
              <h1 class="text-block mt-5 mb-4 text-center">
                <b-iconstack font-scale="1.5" variant="primary">
                  <b-icon stacked icon="house-fill"/>
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Instalaciones</h6>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <!--    Enlaces-->

  </div>
</template>

<script>

export default {
  name: 'controlMenu',
  components: {

  },
  data() {
    return {
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          name: 'operations',
        },
        {
          breadcrumbName: 'Control Operacional',
        },
      ],
    }
  },
  methods: {

  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}
</style>
